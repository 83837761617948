$mainBg: #f6f2eb;
$btnBg: #fec887;
$btnHoverBg: #e9942c;

.preloader {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: $mainBg;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: $btnBg !important;
    color:black
}

.ck-editor__editable {
    min-height: 300px;
}



::-ms-reveal {
    display: none;
}

.btn {

    padding: 6px 12px;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none !important;
    border-radius: 6px;

    &.btn-primary {
        background: $btnBg;
        color: black;
        border-color: $btnBg;

        &:hover,
        &:active,
        &:focus {
            background: $btnHoverBg !important;
            border-color: $btnHoverBg;
        }
    }

    &.btn-outline-primary {
        background: none;
        color: black;
        border-color: $btnBg;

        &:hover,
        &:active,
        &:focus {
            background: $btnBg !important;
            border-color: $btnBg;
        }
    }
}



#mainBody {
    font-size: 14px;


    .info-item-block {
        display: flex;
        gap:20px;
        .title {
            width:200px;
            font-weight: bold;
            max-width: 200px;
            min-width: 200px;
        }
        .value {
            flex-grow: 1;
        }
    }

    .notification-page-list-item {
        display: flex;
        gap:20px;
        padding: 8px 16px;
       
        div:last-child {
            flex-grow: 1;
        }
        &:hover {
            background-color: #eef6ff;
        }
    }

    .profile {
        .profile-container {
            display: flex;
            gap: 20px;

            .profile-block {
                background-color: white;
                border-radius: 10px;
                width: 300px;
                overflow: hidden;

                .header {
                    padding: 20px;

                    .image {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            border-radius: 50%;
                            width: 120px;
                            aspect-ratio: 1 / 1;
                            object-fit: cover;
                            
                        }

                        &:hover {
                            .edit-user-photo {
                                display: flex;
                            }
                        }

                        position: relative;

                        .edit-user-photo {
                            position: absolute;
                            height: 120px;
                            width: 120px;
                            border-radius: 50%;
                            justify-content: center;
                            align-items: center;
                            font-size: 32px;
                            color:#0c70b3;
                            background-color: #ffbb00a1;
                            cursor: pointer;
                            display: none;
                        }

                        margin-bottom: 20px;
                    }

                    .info {
                        text-align: center;
                    }

                    .company-info {
                        a {
                            text-decoration: none !important;
                        }
                        .company-block {
                            background-color: #f1f1f1;
                            border-radius: 6px;
                            padding: 8px 10px;
                            display: flex;
                            gap:10px;
                            align-items: center;
                            img {
                                aspect-ratio: 1 / 1;
                                object-fit: contain;
                                border-radius: 4px;
                                width:40px;
                            }
                            p {
                                font-weight: 500;
                                font-size: 14px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            .setting-block {
                background-color: white;
                flex-grow: 1;
                padding: 20px;
            }

        }
    }

    .home-page-cards {
        display: flex;
        gap: 20px;
    }

    .home-page-statistics {
        background: #ffc165;
        border-radius: 16px;
        min-width: 200px;
        padding: 20px;

        h5 {
            font-size: 18px;
            color: black;
            font-weight: bold;
        }
    }

    .home-page-banner {
        flex-grow: 1;
        background: white;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .content {
            position: relative;
            padding: 30px;

            h2 {
                color: rgb(58, 8, 124);
            }
        }

        img {
            width: 200px;
        }
    }

    .calendar-container {
        border-radius: 10px;
        color: #596780;

        .calendar-container-content {
            display: flex;
        }

        .data-block {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            flex-direction: column;
            border: 1px solid #eef6ff;
            background-color: white;

            * {
                padding: 0;
                margin: 0;
            }

            p {
                color: gray;
            }
        }

        .title-block {
            position: sticky;
            top: -30px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            flex-direction: column;
            border: 1px solid #eef6ff;
            z-index: 10;

            * {
                padding: 0;
                margin: 0;
            }

            p {
                color: gray;
            }
        }

        .calendar-hours-container {
            width: 100px;
        }

        .calendar-week-container {
            flex-grow: 1;
            display: flex;

            .calendar-day-container {
                width: calc(100% / 7);
                position: relative;

                &:nth-child(6),
                &:nth-child(7) {

                    .title-block,
                    .data-block {
                        background-color: #fffdfa !important;


                    }
                }

                &.active {

                    .title-block,
                    .data-block {
                        background-color: #f7fbff !important;


                    }
                }

                .calendar-day-content {
                    position: absolute;
                    height: 100%;
                    z-index: 2;
                    top: 60px;
                    width: 95%;


                    .event {
                        width: 100%;
                        background-color: $btnBg;
                        border-radius: 6px;
                        color: black;
                        font-weight: bold;
                        padding: 8px;
                        cursor: pointer;
                        position: absolute;
                        width: 100%;
                        top: 100px;
                        left: 0;
                        min-height: 40px;
                        overflow: hidden;

                        p {
                            position: absolute;
                            top: 8px;
                            left: 8px;
                            width: 600px;
                        }

                        &:hover {
                            background-color: $btnHoverBg;
                        }
                    }
                }
            }
        }

    }

    .status-badge {
        border: 2px solid;
        border-radius: 6px;
        padding: 4px 8px;
        font-size: 12px;
        display: inline-block;
        margin-bottom: 10px !important;

        &.status-badge-primary {
            border-color: #0c70b3;
            display: inline-block;
            font-weight: bold;
            color: #0c70b3;
        }

        &.status-badge-warning {
            border-color: #ffbb00;
            display: inline-block;
            font-weight: bold;
            color: #ffbb00;
        }

        &.status-badge-success {
            border-color: #28a745;
            display: inline-block;
            font-weight: bold;
            color: #28a745;
        }

        &.status-badge-danger {
            border-color: #dc3545;
            display: inline-block;
            font-weight: bold;
            color: #dc3545;
        }
    }

    .form-control {
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 6px;

        &:focus {
            border-color: $btnBg;
            box-shadow: 0 0 0 0.25rem rgba(253, 165, 13, 0.25);
        }
    }

    a {
        color: black !important;
        text-decoration: none;

        &.btn-success {
            color: white !important;
        }

        &:hover {
            text-decoration: underline;
        }
    }


    .filter {
        position: relative;

        .filter-container {
            z-index: 1060;
            position: absolute;
            top:40px;
            background-color: white;
            right:0px;
            width:500px;
            padding: 20px;
            border-radius: 8px;
            overflow: hidden;
            border:1px solid #fec887;
        }
    }


    nav {
        background-color: white;
        position: relative;
        z-index: 1000;


        .notifications {
            position: relative;
            .messages {
                border:1px solid #ffbb00;
                position: absolute;
                top:40px;
                right:0px;
                background-color: white;
                border-radius: 6px;
                width:400px;
                overflow: hidden;
                ul {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    flex-direction: column;
                    gap:0;
                    li {
                        padding:8px 16px;
                        border-bottom: 1px solid #eef6ff;
                        width: 100%;
                        &:hover {
                            background-color: #eef6ff;
                        }
                    }
                }
            }
        }
    }

    .root-container {
        margin: 24px;
        border-radius: 20px;
        overflow: hidden;
        height: calc(100vh - 48px);
        box-shadow: 0 0 30px -10px rgba(37, 37, 37, 0.213);
    }

    .nav-container {
        display: flex;
        padding: 0 30px;
        align-items: center;

        a {
            text-decoration: none !important;
        }

        .finder {
            flex-grow: 1;
            max-width: 600px;
            position: relative;

            .form-control {
                height: 40px;
            }

            button {
                position: absolute;
                bottom: 4px;
                right: 4px;
                background: none;
                border: none;
                border-radius: 6px;
                height: 32px;
                width: 32px;

                &:hover {
                    background: $btnBg;
                }
            }

            .result {
                position: absolute;
                top: 42px;
                border-radius: 6px;
                width: 100%;
                left: 0px;
                background-color: white;
                padding: 20px;

                .consts-list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;

                    .consts-list-item {
                        background-color: #f1f1f1;
                        padding: 8px 16px;
                        border-radius: 4px;
                        transition: .2s ease-in-out;

                        &:hover {
                            background-color: #fec887;
                        }
                    }
                }

                .result-block {
                    margin-bottom: 10px;

                    p {
                        font-weight: 500;
                        font-size: 20px;
                        margin-bottom: 0;
                    }

                    .result-list {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 10px;

                        li {
                            width: 100%;
                        }

                        .result-list-item {
                            background-color: #f1f1f1;
                            width: 100%;
                            padding: 8px 16px;
                            display: flex;
                            justify-content: space-between;
                            border-radius: 4px;
                            transition: .2s ease-in-out;

                            &:hover {
                                background-color: #fec887;
                            }
                        }
                    }
                }
            }
        }

        .brand {
            width: 300px;
            font-size: 30px;
            font-weight: bolder;
            color: $btnHoverBg;
        }

        ul {
            list-style: none;
            display: flex;
            gap: 20px;
            align-items: center;
            margin: 0;
            padding: 10px 0;
        }
    }

    .main-layout {
        display: flex;
        height: 100%;

        .main-layout-content-block-container {
            flex-grow: 1;
            background-color: #f6f6fa;
            padding: 30px;
            overflow-y: auto;
            padding-bottom: 100px;
        }
    }

    aside {
        background-color: white;
        min-width: 300px;
        width: 300px;
        max-width: 300px;
        padding: 30px;
        padding-bottom: 100px;
        overflow: auto;

        .main-menu {
            list-style: none;
            margin: 0;
            padding: 0;

            &>li {
                margin-bottom: 30px;

                h4 {
                    font-weight: 600;
                    color: #2a3452;
                    margin-bottom: 20px;
                }
            }

            .main-menu-links {
                list-style: none;
                padding: 0;
                margin: 0;

                .main-menu-links-item {
                    display: flex;
                    gap: 10px;

                    span {
                        width: 20px;
                    }
                }

                li {
                    padding: 4px 10px;
                    font-size: 16px;
                    position: relative;

                    p {
                        margin-bottom: 6px;
                    }

                    a {
                        color: #b7bac4 !important;
                        text-decoration: none !important;

                        &:hover {
                            color: $btnBg !important;
                        }
                    }

                    &.active {
                        &::after {
                            width: 10px;
                            height: calc(100% - 10px);
                            left: -30px;
                            border-top-right-radius: 6px;
                            border-bottom-right-radius: 6px;
                            top: 5px;
                            position: absolute;
                            content: "";
                            background-color: $btnHoverBg;
                        }

                        a {
                            color: $btnHoverBg !important;
                        }
                    }
                }
            }
        }
    }



    .user-list-item {
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 6px;
        padding-right: 12px;
        border-radius: 4px;

        &:hover {
            background-color: #d3d9ff;
        }

        &:active {
            background-color: #acb6f1;
        }

        p {
            margin-bottom: 0;

        }

        img {
            border: 1px solid #b8b8b8;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            object-fit: cover;
        }
    }

    .manage-table {
        border-radius: 10px;
        background-color: white;
        padding: 20px;

        .header {

            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                font-size: 20px;
                margin-bottom: 0;
                padding: 0;
            }

            ul {
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none !important;
                gap: 20px;
            }
        }

        .body {
            a {
                text-decoration: none !important;
            }


        }

        .footer {
            .manage-table-pagination {
                display: flex;
                gap: 6px;

                button {
                    background-color: white;
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: none;
                    font-weight: 500;

                    &:hover {
                        background-color: $btnBg;
                    }

                    &.active {
                        background-color: $btnHoverBg;
                        color: white;
                    }
                }
            }
        }
    }

    .white-form-block {
        border-radius: 10px;
        background-color: white;
        padding: 20px;
        max-width: 1000px;

        label {
            min-width: 200px;
            width: 200px;
            margin-right: 30px;
        }

        .form-check-label {
            width: auto !important;
        }
    }

    .toast-container {
        right: 50px;
        top: 110px;
    }

    #root>div>div.main-layout div.ck.ck-reset.ck-editor.ck-rounded-corners>div.ck.ck-editor__main>div {
        height: 200px;
    }

    .dropdown-toggle {
        width: 40px;

        &::after {
            display: none !important;
        }
    }

    .application-details-container {
        .application-details-item {
            display: flex;
            padding: 6px 12px;
            margin-bottom: 20px;
            border-radius: 8px;

            &:hover {

                background-color: #e7eaff;
            }

            &.not-hovered {
                &:hover {
                    background-color: white !important;
                }

            }

            .title {
                width: 250px;
                color: grey;
            }

            .value {
                font-weight: 500;
            }
        }
    }
}